import './permissions';

import ReactOnRails from 'react-on-rails';

import ApplicationPermission from '../react/components/application-permission';
import FlashAlerts from '../react/components/flash-alerts';

ReactOnRails.register({
  ApplicationPermission,
  FlashAlerts
});

$(() => {
  $('#admin_user_image').on('focusout', () => {
    $('#avatar').attr('src', $('#admin_user_image').val());
  });

  $('#admin_user_image').on('keydown', () => {
    $('#avatar').attr('src', $('#admin_user_image').val());
  });
});


const toggleCheckboxes = () => {
  const checkboxes = document.querySelectorAll('input[type="checkbox"][select_all=true]');
  const anyChecked = Array.from(checkboxes).some(checkbox => checkbox.checked);

  checkboxes.forEach(checkbox => {
    checkbox.checked = !anyChecked;
  });
};

$(()=>{
  document.querySelectorAll('a[data-select-all]').forEach((element)=>{
    element.addEventListener("click", (event)=>{
      event.preventDefault();
      toggleCheckboxes();
    })
  })
})
