import React from 'react'

class RolesList extends React.Component {
  constructor(props) {
    super(props);
  }

  optionForSelect(collection, selectedRole) {
    let options

    options = collection.map( (role, index) => { return(
        <option key={`role-${role}`} value={role[1]}>{role[0]}</option>
      )
    })

    options.unshift(<option key='role-empty' value=''></option>)
    return options
  }

  render() {
    if(this.props.active){
      let defaultValue= this.props.selectedRole ? this.props.selectedRole.id : ''
      return (
        <div className="form-group select optional admin_user_platform_role_users_role_id is-empty">
          <label className="control-label select optional role-list-label" htmlFor={`admin_user_platform_role_users_attributes_${this.props.index}_role_id`}>Role</label>
          <select defaultValue={defaultValue} className="form-control select optional" name={`admin_user[platform_role_users_attributes][${this.props.index}][role_id]`} >
            { this.optionForSelect(this.props.collection, this.props.selectedRole) }
          </select>
        </div>
      )
    }else{
      return (
        <input type='hidden'
               name={`admin_user[platform_role_users_attributes][${this.props.index}][role_id]`}
               value=''
        />
      )
    }
  }
}

export default RolesList;
