import React from 'react'
import Toggle from 'react-toggle'

class PermissionToggle extends React.Component {
  constructor(props) {
    super(props);
  }

  platformRoleUsersId(){
    if(this.props.platformRoleUsersId){
      return (
        <input className="form-control hidden" value={ this.props.platformRoleUsersId } type="hidden" name={`admin_user[platform_role_users_attributes][${ this.props.index }][id]`} />
      )
    }
    return(null)
  }

  action(){
    if(this.props.active){
      return (
        <input className="form-control hidden" value={ this.props.platformId } type="hidden" name={`admin_user[platform_role_users_attributes][${ this.props.index }][platform_id]`} />
      )
    }else{
      return(
        <input className="form-control hidden" value='true' type="hidden" name={`admin_user[platform_role_users_attributes][${ this.props.index }][_destroy]`} />
      )
    }
  }

  render() {
    return (
      <div className='form-group'>
        { this.action() }
        { this.platformRoleUsersId() }
        <label className='control-label'>{ this.props.platformName }</label>
        <br/>
        <Toggle
          defaultChecked={this.props.active}
          icons={false}
          onChange={ () => { this.props.handleChange(this.props.active) } } />
      </div>
    )
  }
}

export default PermissionToggle;
