import React from 'react'
import PermissionToggle from './permission-toggle'
import RolesList from './roles-list'

class ApplicationPermission extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.state = {
      platformName: props.platformName,
      active: props.active,
      platformId: props.platformId,
      roles: props.roles,
      selectedRole: props.selectedRole,
      index: props.index,
      collection: props.collection,
      platformRoleUsersId: props.platformRoleUsersId
    };
  }

  handleChange(active) {
    this.setState({ active: !active })
  }

  render() {
    return(
      <div className = 'row'>
        <div className='col-4'>
          <PermissionToggle
            platformName = { this.state.platformName }
            platformId = { this.state.platformId }
            index = { this.state.index }
            active = { this.state.active }
            handleChange = { this.handleChange }
            platformRoleUsersId = { this.state.platformRoleUsersId }
          />
        </div>
        <div className='col-8'>
          <RolesList
            rolesList = { this.state.rolesList }
            active = { this.state.active }
            roles = { this.state.roles }
            selectedRole = { this.state.selectedRole }
            index = { this.state.index }
            collection = { this.state.collection }
          />
        </div>
      </div>
    )
  }
}

export default ApplicationPermission;

