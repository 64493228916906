$(document).ready(function() {  
  $("input[type=checkbox]").change(function() {
    if($(this).is(":checked") && /DELETE|UPDATE|CREATE|IMPORT|EXPORT$/.test(this.id)) {
      var id = this.id.replace(/DELETE|UPDATE|CREATE|IMPORT|EXPORT$/, 'VIEW');
      $("#" + id).prop('checked', true);
    }

    if(!$(this).is(":checked") && /VIEW$/.test(this.id)) {
      var id = this.id;
      $.each(['DELETE', 'UPDATE', 'CREATE', 'EXPORT'], function( index, value ) {
        var new_id = id.replace('VIEW', value);
        $("#" + new_id).prop('checked', false);
      });
    }
  });

  const position = () => $(document).height() - $(window).scrollTop() - $(window).height();

  const width_actions_floating = () => $('.actions-floating').width($('.app-container .page').width());

  const stick_header_and_footer = function() {
    width_actions_floating();
    if (position() <= 30) {
      return $('.actions-floating').removeClass('on').addClass('off');
    } else {
      $('.actions-floating').addClass('fixed');
      return $('.actions-floating').removeClass('off').addClass('on');
    }
  };

  $(function() {
    if ($('.actions-floating').length > 0) {

      stick_header_and_footer();

      $(window).on('scroll', ()=> stick_header_and_footer());

      return $(window).resize(()=> stick_header_and_footer());
    }
  });
});
