import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";

class FlashAlerts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      alerts: props.alerts
    };
    window.setAlerts = (alerts) => this.setState({ alerts });
  }

  render() {
    const { alerts } = this.state;
    return(
      <div className="alerts">
        {
          Object.keys(alerts).map((alertType) => {
            const messages = [alerts[alertType]].flat();
            return (
              <div key={alertType} className={classnames({[alertType]: true, alert: true})}>
                <button className="close" aria-hidden data-dismiss="alert" type="button">&times;</button>
                <ul>
                  { messages.map((message, index) => <li key={index}>{message}</li>) }
                </ul>
              </div>
            );
          })
        }
      </div>
    )
  }
}

FlashAlerts.propTypes = {
  alerts: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)])
  ),
}

FlashAlerts.defaultProps = {
  alerts: [],
}

export default FlashAlerts;
